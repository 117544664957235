<template>
  <div class="d-flex" style="gap: 8px; padding: 0">
    <b-button size="sm" @click="onClickEditButton" class="aktionenButton">
      <i class="flaticon-edit icon-1x" style="color: black; padding: 0"></i>
    </b-button>
    <b-button size="sm" @click="onClickWheelButton" class="aktionenButton">
      <i class="fas fa-cog" style="padding: 0"></i>
    </b-button>
    <b-button size="sm" @click="onClickDeleteButton" class="aktionenButton">
      <i class="flaticon-delete icon-1x" style="padding: 0"></i>
    </b-button>
  </div>
</template>
<script>
import { SET_REISETERMIN_EDIT_DATA } from '@/core/produkte/reisetermine/stores/reiseterminplanung.module';
import apiService from '@/core/common/services/api.service';

export default {
  props: {},
  data() {},
  methods: {
    onClickEditButton() {
      this.params.api.deselectAll();
      this.$store.commit(SET_REISETERMIN_EDIT_DATA, this.params.data);
      this.$root.$emit('bv::show::modal', 'modalReiseterminBearbeiten');
    },
    onClickWheelButton() {
      window.open('/#/reisen/' + this.params.data.reise.reisekuerzel + '/reisestammdaten', '_blank');
    },
    async onClickDeleteButton() {
      const confirmed = await this.$bvModal.msgBoxConfirm('Willst du den Reisetermin wirklich löschen?', {
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
      });
      if (confirmed && this.params.data.id) {
        apiService
          .delete('/reisetermin', {
            reisekuerzel: this.params.data.reiseterminkuerzel.substring(0, 6),
            startdatum: this.params.data.startdatum,
          })
          .then(_ => {
            this.params.api.onFilterChanged();
          });
      }
    },
  },
};
</script>
<style scoped>
.aktionenButton {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
